import { styled } from 'styled-components';
import { buildStaticUrl } from 'app/utils';
import { Link } from '@remix-run/react';

const Container = styled.div`
  display: flex;
`;

const Img = styled.img`
  width: 203px;
  height: 40px;
  max-width: unset;
`;

const HappyLogo = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <Link to="/?redirect=no">
        <Img
          alt="logo"
          src={`${buildStaticUrl('/teachers/headers/happy-logo.svg')}`}
          loading="lazy"
        />
      </Link>
    </Container>
  );
};

export { HappyLogo };
