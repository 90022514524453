import { styled } from 'styled-components';
import { cssMainGrid } from '../Common';

export const HeaderOuterContainer = styled.main`
  ${cssMainGrid}
  z-index: ${({ theme }) => theme.zIndex.header.container};
  position: relative;
  background: ${({ theme }) => theme.colors.black100};
`;

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  grid-column: 1 / -1;
  position: relative;
`;
