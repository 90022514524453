import { cssMainGrid } from '@components/Common';
import { styled } from 'styled-components';

export const Container = styled.div`
  ${cssMainGrid}
  padding: 0 24px;
  gap: 24px;

  ${({ theme: { down } }) => down('tabletMd')} {
    max-width: 1024px;
    gap: 16px;
  }
  ${({ theme: { down } }) => down('tabletSm')} {
    max-width: 768px;
    gap: 24px;
  }
  ${({ theme: { down } }) => down('phone')} {
    max-width: 768px;
    gap: 16px;
  }
  ${({ theme: { down } }) => down('phoneSm')} {
    max-width: 768px;
  }
`;

export const HeaderContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.07) 0px 20px 20px -20px;
  background-color: ${({ theme }) => theme.colors.black100};
`;

export const FooterContainer = styled.div`
  position: relative;
  background: rgb(255, 255, 255);
  border-top: 2px solid rgb(240, 242, 245);
  padding: 40px 0px;
`;

export const Content = styled.div`
  grid-column: 1 / -1;
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.sticky};
`;
